import React, { useState } from "react";
import Logo from "./assets/Logo.svg";
import Sparkle from "./assets/Sparkle.svg";
import Response1 from "./assets/clienttestimonials1.png";
import Response2 from "./assets/clienttestimonials2.png";
import Response3 from "./assets/clienttestimonials3.png";
import Response4 from "./assets/clienttestimonials4.png";
import Response5 from "./assets/clienttestimonials5.png";
import Response6 from "./assets/clienttestimonials6.png";
import { PopupButton } from "react-calendly";

const Home = () => {
  return (
    <div className="homepage">
      <main className="homepage">
        <header>
          <div className="box">
            <div className="header-content">
              <img src={Logo} alt="" />
              <PopupButton
                url="https://calendly.com/withleads"
                rootElement={document.getElementById("root")}
                text="Schedule a Call"
                className="gradient-btn"
              />
            </div>
            <div className="seperator"></div>
          </div>
        </header>
        <section className="hero-section">
          <div className="box">
            <div className="hero-content">
              <div className="blue-glow"></div>
              <div className="blue-glow-small"></div>
              <div className="blue-glow-medium"></div>
              <div className="orange-glow-small"></div>
              <div className="orange-glow"></div>
              <img src={Sparkle} alt="" />
              <h1>
                <span className="blue-gradient-text">Say</span>{" "}
                <span className="orange-gradient-text">Goodbye</span>{" "}
                <span className="blue-gradient-text">to the</span>{" "}
                <span className="orange-gradient-text">Stress</span>{" "}
                <span className="blue-gradient-text">of What Might Happen</span>{" "}
                <span className="blue-gradient-text">If You Don’t</span>{" "}
                <span className="orange-gradient-text">Land</span>{" "}
                <span className="blue-gradient-text">Another</span>{" "}
                <span className="blue-gradient-text">Client Soon</span>
              </h1>
              <p>
                <span className="blue-gradient-text">Emails suck…</span>
                <span className="orange-gradient-text">97%</span>{" "}
                <span className="blue-gradient-text">
                  of them…But you’re here because my
                </span>{" "}
                <span className="orange-gradient-text">emails don’t!</span>
              </p>
              <PopupButton
                url="https://calendly.com/withleads"
                rootElement={document.getElementById("root")}
                text="Schedule a Call"
                className="gradient-btn"
              />
            </div>
          </div>
        </section>
        <section className="getback-section">
          <div className="box">
            <div className="get-back-content">
              <h2>
                <span className="blue-gradient-text">BEFORE YOU</span>{" "}
                <span className="orange-gradient-text">GET BACK</span>{" "}
                <span className="blue-gradient-text">TO YOUR </span>{" "}
                <span className="blue-gradient-text"> DAILY</span>{" "}
                <span className="orange-gradient-text">GRIND</span>
              </h2>
              <p>
                <span className="blue-gradient-text">
                  Let me tell you something funny…
                </span>
              </p>
              <h6 className="blue-gradient-text">I have:</h6>
              <ul>
                <li className="blue-gradient-text">
                  • Generated 32 leads for a SaaS cloud security firm in 1 month
                  (they closed 4 clients with avg contract size &gt;$5k each)
                </li>
                <li className="blue-gradient-text">
                  • Closed 6 clients for a US influencers agency with contracts
                  worth over 100K each
                </li>
                <li className="blue-gradient-text">
                  • Made $300K+ in business for a digital marketing agency over
                  1 year
                </li>
                <li className="blue-gradient-text">
                  • Helped close 3 monthly contracts for a video advertisement
                  agency with $13K, $20K, and $50K each.
                </li>
              </ul>
              <h6 className="blue-gradient-text">
                <i>So what’s funny here? It’s that…</i>
              </h6>
              <h5 className="blue-gradient-text">
                I did all of this writing SIMPLE, HUMOROUS emails that sounded
                like someone talking to a bro whom they have all the rights to
                slap on the ace*!
              </h5>
              <h6 className="blue-gradient-text">(it’s that easy!)</h6>
            </div>
          </div>
        </section>
        <section className="response-section">
          <div className="box">
            <div className="response-content">
              <h2>
                <span>A</span>{" "}
                <span className="orange-gradient-text">HAPPY</span>{" "}
                <span>Prospect Is </span>{" "}
                <span className="orange-gradient-text">HALF</span>{" "}
                <span>Your Client </span> <span>Already!</span>
              </h2>
              <p>You Will Receive Similar Responses Daily :)</p>
              <img src={Response1} alt="" />
              <img src={Response2} alt="" />
              <img src={Response3} alt="" />
              <img src={Response4} alt="" />
              <img src={Response5} alt="" />
              <img src={Response6} alt="" />
            </div>
          </div>
        </section>
        <section className="getback-section gbs-1">
          <div className="box">
            <div className="get-back-content">
              <h2>
                <span className="blue-gradient-text">Listen Up…</span>{" "}
                <span className="orange-gradient-text">Emails</span>{" "}
                <span className="blue-gradient-text">Work! </span>{" "}
              </h2>
              <h3>
                <span className="blue-gradient-text">I’m a</span>{" "}
                <span>
                  <a
                    className="orange-gradient-text"
                    href="https://www.upwork.com/freelancers/coldemailcopywriter"
                  >
                    Top 3% copywriter on Upwork.
                  </a>
                </span>{" "}
                <span className="blue-gradient-text">
                  (yeah feel free to smash that link)
                </span>
              </h3>
              <h3>
                <span className="blue-gradient-text">
                  And I know agencies <b>SUCK</b> (sorry if you’re one… I only
                  mean lead gen agencies)
                </span>
              </h3>
              <h6 className="blue-gradient-text">That’s because:</h6>
              <ul>
                <li className="blue-gradient-text">
                  • They have more clients than they can handle
                </li>
                <li className="blue-gradient-text">
                  • Small, lazy, incompetent teams they’ve hired based on how
                  cheap each talent is…
                </li>
                <li className="blue-gradient-text">
                  • And they follow frameworks stolen from the internet that
                  1000 others follow as well
                </li>
              </ul>
              <h6 className="blue-gradient-text">
                <b>Of course, they failed you…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>But hey - how often do you check your inbox? </b>
              </h6>
              <h6 className="blue-gradient-text">
                <b>My guess is you check it at least 13 times a day.</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>
                  Check it now and you’ll see my email was the ONLY GOOD EMAIL
                  you’ve received in a loooong time…
                </b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>(I bet my bragging rights for a month on it)</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>And yet it’s so simple…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>So ordinary…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>So laid-back…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>So well-researched…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>So relevant…</b>
              </h6>

              <h6 className="blue-gradient-text">
                <b>So well-crafted you couldn’t help but respond.</b>
              </h6>
              <h6 className="blue-gradient-text">
                <b>
                  AND THAT’S EXACTLY WHAT YOUR POTENTIAL CLIENTS WILL READ FROM
                  YOU WHEN I DO YOUR OUTREACH
                </b>
              </h6>

              <h5 className="blue-gradient-text">
                (I’m tired of writing now.)
              </h5>
              <h6>
                <span className="blue-gradient-text">
                  <i>Let’s </i>
                </span>{" "}
                <span>
                  <i>
                    <a
                      className="orange-gradient-text"
                      href="https://calendly.com/withleads"
                    >
                      Schedule a Call?
                    </a>
                  </i>
                </span>
              </h6>
            </div>
          </div>
        </section>
        <footer>
          <div className="box">
            <div className="footer-content">
              <img src={Logo} alt="" />
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
};

export default Home;
